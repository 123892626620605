import { DEFAULT_PALETTE } from '@typeform/ginger/dist/constants/palettes'
import { CornersStyle } from 'components/modules/module-container'

export const defaultProps = {
  palette: DEFAULT_PALETTE,
  cornersStyle: CornersStyle.Default,
}

export const testIds = {
  Container: 'media-big-module',
  Media: 'media-big-module--media',
  Content: 'media-big-module--content',
  Title: 'media-big-module--title',
  SubTitle: 'media-big-module--body',
  ActionBar: 'media-big-module--action-bar',
  TextColumn: 'media-big-module--text-column',
  TextColumnTitle: 'media-big-module--text-column--title',
  TextColumnBody: 'media-big-module--text-column--body',
}
