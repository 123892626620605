import { useCallback, useMemo } from 'react'
import { BREAKPOINTS } from '@typeform/ginger/dist/constants/design'
import { onTrackItemClickEvent } from '@typeform/ginger/dist/util/tracking'
import { ButtonSize } from 'components/atoms/button'
import ActionBar, {
  TActionBarMouseEventHandler,
} from 'components/molecules/action-bar'

import {
  MediaBigModuleContainer,
  MediaBigGrid,
  Title,
  SubTitle,
  TitleContainerGridItem,
  MediaContainerGridItem,
  TextColumnGridItem,
  TextColumnTitle,
  TextColumnContentBody,
  ActionBarContainerGridItem,
} from './media-big-module.styles'
import { TMediaBigModuleProps } from './media-big-module.types'
import { defaultProps, testIds } from './media-big-module.constants'

const MediaBigModule = ({
  palette,
  title,
  subTitle,
  textColumns,
  actionBar,
  media,
  smallMedia,
  trackEvent,
  trackExperimentEvent,
  ...rest
}: TMediaBigModuleProps) => {
  const hasTitle = useMemo(() => !!(title || subTitle), [title, subTitle])

  const handleActionClick: TActionBarMouseEventHandler = useCallback(
    ({ link }) => {
      onTrackItemClickEvent(link, trackEvent, trackExperimentEvent)
    },
    [trackEvent, trackExperimentEvent]
  )

  return (
    <MediaBigModuleContainer
      data-testid={testIds.Container}
      palette={palette}
      {...rest}
    >
      <MediaBigGrid>
        {hasTitle && (
          <TitleContainerGridItem>
            <Title data-testid={testIds.Title}>{title}</Title>
            <SubTitle data-testid={testIds.SubTitle}>{subTitle}</SubTitle>
          </TitleContainerGridItem>
        )}
        <MediaContainerGridItem
          data-testid={testIds.Media}
          media={media}
          smallMedia={smallMedia}
          hasTitle={hasTitle}
          thresholdBreakpoint={BREAKPOINTS.sm}
        />
        {textColumns?.map((textColumn, index) => (
          <TextColumnGridItem
            data-testid={testIds.TextColumn}
            key={textColumn.title || index}
            index={index}
          >
            <TextColumnTitle data-testid={testIds.TextColumnTitle}>
              {textColumn.title}
            </TextColumnTitle>
            <TextColumnContentBody
              data-testid={testIds.TextColumnBody}
              forwardedAs={
                typeof textColumn.body !== 'string' ? 'div' : undefined
              }
            >
              {textColumn.body}
            </TextColumnContentBody>
          </TextColumnGridItem>
        ))}
        {actionBar && (
          <ActionBarContainerGridItem>
            <ActionBar
              data-testid={testIds.ActionBar}
              palette={palette}
              size={ButtonSize.Large}
              onClick={handleActionClick}
              {...actionBar}
            />
          </ActionBarContainerGridItem>
        )}
      </MediaBigGrid>
    </MediaBigModuleContainer>
  )
}

MediaBigModule.defaultProps = defaultProps

export default MediaBigModule
