import styled from 'styled-components'
import mediaQueries from '@typeform/ginger/dist/util/mediaqueries'
import { spacing } from '@typeform/ginger/dist/util/spacing'
import { Palette } from '@typeform/ginger/dist/constants/palettes'
import { NeutralColors } from '@typeform/ginger/dist/constants/colors'
import Grid, {
  GridItem,
  gridPosition,
} from 'components/atoms/grid-container/next'
import Text, {
  SIZE as TEXT_SIZE,
} from '@typeform/ginger/dist/components/typography/text/next'
import Headline, {
  SIZE as HEADLINE_SIZE,
} from '@typeform/ginger/dist/components/typography/headline/next'
import ModuleContainer from 'components/modules/module-container'
import ModuleMediaContainer from 'components/atoms/module-media-container'

import {
  TMediaBigModuleContainerProps,
  TMediaContainerGridItemProps,
  TTextColumnGridItemProps,
} from './media-big-module.types'

export const MediaBigModuleContainer = styled(
  ModuleContainer
).attrs<TMediaBigModuleContainerProps>(
  ({ palette, textColor, backgroundColor, ...props }) => ({
    ...props,
    backgroundColor:
      backgroundColor ||
      (palette === Palette.Negative ? NeutralColors.Ink : NeutralColors.White),
    textColor:
      textColor ||
      (palette === Palette.Negative ? NeutralColors.White : NeutralColors.Ink),
  })
)<TMediaBigModuleContainerProps>`
  padding: ${spacing(8, 0)};

  ${mediaQueries.sm`
    padding: ${spacing(9, 0)};
  `}

  ${mediaQueries.md`
    padding: ${spacing(10, 0)};
  `}

  ${mediaQueries.xl`
    padding: ${spacing(12, 0)};
  `}
`

export const MediaBigGrid = styled(Grid)`
  row-gap: 0;
`

export const TitleContainerGridItem = styled(GridItem).attrs({
  column: gridPosition().fill(),
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${spacing(2)};

  ${mediaQueries.lg`
    gap: ${spacing(3)};
  `}
`

export const Title = styled(Headline).attrs({
  forwardedAs: 'h2',
  extraSmallViewportsSize: HEADLINE_SIZE.ONE,
  smallViewportsSize: HEADLINE_SIZE.ONE,
  mediumViewportsSize: HEADLINE_SIZE.TWO,
  largeViewportsSize: HEADLINE_SIZE.THREE,
})`
  text-align: center;
`

export const SubTitle = styled(Text).attrs({
  extraSmallViewportsSize: TEXT_SIZE.S,
  smallViewportsSize: TEXT_SIZE.S,
  mediumViewportsSize: TEXT_SIZE.M,
  largeViewportsSize: TEXT_SIZE.M,
})`
  text-align: center;
`

export const MediaContainerGridItem = styled(
  ModuleMediaContainer
).attrs<TMediaContainerGridItemProps>({
  forwardedAs: GridItem,
  column: gridPosition().fill(),
})<TMediaContainerGridItemProps>`
  margin-top: ${({ hasTitle }) => (hasTitle ? spacing(4) : 0)};
`

export const TextColumnGridItem = styled(
  GridItem
).attrs<TTextColumnGridItemProps>(({ index }) => ({
  column: {
    base: gridPosition().fill(),
    sm: gridPosition()
      .start(1 + index * 4)
      .size(4),
    md: gridPosition()
      .start(3 + index * 4)
      .size(4),
    xl: gridPosition()
      .start(4 + index * 3)
      .size(3),
  },
}))<TTextColumnGridItemProps>`
  display: flex;
  flex-direction: column;
  margin-top: ${spacing(4)};
  gap: ${spacing(1)};

  ${mediaQueries.sm`
    margin-top: ${spacing(8)};
  `}

  ${mediaQueries.md`
    gap: ${spacing(2)};
  `}
`

export const TextColumnTitle = styled(Text).attrs({
  emphasised: true,
  extraSmallViewportsSize: TEXT_SIZE.M,
  smallViewportsSize: TEXT_SIZE.M,
  mediumViewportsSize: TEXT_SIZE.M,
  largeViewportsSize: TEXT_SIZE.L,
})``

export const TextColumnContentBody = styled(Text).attrs({
  extraSmallViewportsSize: TEXT_SIZE.S,
  smallViewportsSize: TEXT_SIZE.S,
  mediumViewportsSize: TEXT_SIZE.M,
  largeViewportsSize: TEXT_SIZE.M,
})``

export const ActionBarContainerGridItem = styled(GridItem).attrs({
  column: gridPosition().fill(),
})`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${spacing(5)};

  ${mediaQueries.sm`
    margin-top: ${spacing(8)};
  `}
`
